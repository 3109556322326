import { useWindowSize } from '@vueuse/core'
export default () => {
  const { width, height } = useWindowSize()

  const rwd = {
    xs: 600, // 0-600 直式手機
    sm: 900, // 601-900 橫式手機
    md: 1190, // 901-1190 直式平板 橫式手機
    lg: 1441, // 1201-1441 橫式平板 直式電腦
    xl: 1920, // 1367-1920 橫式電腦
    '2xl': 3000,
  }

  const isMobile = computed(() => width.value <= rwd.sm)
  const isTablet = computed(() => width.value > rwd.sm && width.value <= rwd.md)
  const isPC = computed(() => width.value > rwd.md)

  const orientation = computed(() => (width.value > height.value ? 'landscape' : 'portrait'))

  const nowSize = computed(() => {
    // rwd is all width range, give me now width is match which size
    // ex: width = 1024, and find 900 < 1024 < 1190 in rwd, so ouput is 'md'
    const sizes = Object.keys(rwd)
    return sizes.find((size) => {
      const min = sizes.indexOf(size) === 0 ? 0 : rwd[sizes[sizes.indexOf(size) - 1]]
      const max = rwd[size]
      return width.value > min && width.value <= max
    })
  })

  const matchSize = (sizes) => {
    // inputs sizes = ['xs', 'sm', ...]
    // output true/false: now width.value is match sizes range

    if (Array.isArray(sizes)) return sizes.includes(nowSize.value)
    else {
      const sizeWidth = rwd[sizes]
      return width.value <= sizeWidth
    }
  }

  const matchWidth = (widths = []) => {
    const sizes = Object.keys(rwd)

    // map widths to sizes, need to fill final width when widths length < sizes length, output sizeMap
    // ex: widths = [1,3,5,7] => sizeMap = [['xs', 1],['sm', 3],['md', 5],['lg', 7], ...]
    // ex: widths = [1,3] => sizeMap = [['xs', 1],['sm', 3],['md', 3],['lg', 3], ...]
    const sizeMap = sizes.map((size, index) => {
      return [size, widths[index] || widths[widths.length - 1]]
    })

    // find nowSize is match which size, output sizeMap width
    const nowSizeWidth = sizeMap.find((size) => size[0] === nowSize.value)
    return nowSizeWidth ? nowSizeWidth[1] : 0
  }

  const genRwdObject = (datas = [], isKey = false) => {
    const sizes = isKey ? Object.keys(rwd) : Object.values(rwd)
    const ans = {}

    sizes.forEach((size, index) => {
      ans[size] = datas[index] || datas[datas.length - 1]
    })

    return ans
  }

  return {
    isMobile,
    isTablet,
    isPC,
    width,
    height,
    nowSize,
    orientation,
    matchSize,
    matchWidth,
    genRwdObject,
  }
}
